.mt-6 {
  margin-top: 4rem !important;
}

.loader {
  position: absolute;
  top: 40%;
  left: 45%;
  width: 60px !important;
  height: 60px !important;
  z-index: 40;
}

.template-row {
  display: flex;
  justify-content: flex-start;
}

.template-list-view {
  cursor: pointer;
  box-shadow: 0px 1px 3px 3px #eaeaea;
}

.template-list-view:hover {
  background-color: rgba(0, 0, 0, 0.075) !important;
}

.close-icn {
  cursor: pointer;
}

.template-count-box {
  border: 1px solid #eaeaea;
  background-color: white;
  border-radius: 5px;
  margin-right: 47px;
  box-shadow: 0 4px 0 10px 10px;
  padding: 10px;
  font-size: 10px;
  color: #8c8c8c;
  width: 200px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}

.template-count {
  font-size: 30px;
  color: #996c84;
}

.margin-0 {
  margin-bottom: 0;
}

.template-list-view-table > div {
  margin-top: 5px;
}

.pl-2 {
  padding-left: 2rem !important;
}

.create-btn {
  background: #ffef5a;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #ffef5a, #fbce2f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #ffef5a, #fbce2f);
  border: none;
  font-size: 14px;
  color: #626262;
  text-transform: capitalize !important;
}

.template-card {
  box-shadow: 0px 2px 20px 0px #8787871a;
  background-color: white;
  border-radius: 10px;
}

.template-icon {
  width: 31px;
}

.text-purple {
  font-weight: 500;
  color: #88305f;
  font-size: 15px;
}

.flex-center {
  justify-content: center !important;
}

button {
  font-size: 15px !important;
}

.form-group {
  margin-bottom: 5px;
}

.field-label {
  color: #88305f;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
}

.section-name {
  color: #434242;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 11px;
  background-color: white;
}

.card.shadow {
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px;
  border-color: #f2f2f2;
}

.card-body {
  padding: 10px 14px;
}

.form-table {
  background: rgba(233, 233, 233, 0.5);
  border-radius: 10px;
  padding: 10px 27px 0 27px;
  border: 1px solid #f1f1f1;
}

.document-viewer {
  padding: 0 15px 0 30px;
}

.document-viewer > iframe {
  height: calc(100vh - 210px);
}

#panel1bh-header {
  border: 1px solid #88305f;
}

.first-header {
  background: linear-gradient(90deg, #520930 0.82%, #ff7b5f);
  border: 1px solid #f2f2f2;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.1);
  color: #fff !important;
}

.second-header {
  background: linear-gradient(90deg, #ffef5a, #fbce2f);
  border: 1px solid #ffe16d;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.25);
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 15px 0 !important;
}

@mixin avatar($size) {
  width: $size;
  height: $size;
  background-color: #88305f;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  .initials {
    position: relative;
    // top: $size * 0.1;
    top: 2px;
    font-size: $size * 0.4;
    line-height: $size * 0.7;
    color: #fff;
    font-family: Roboto;
    font-weight: normal;
  }
}

.avatar-circle {
  @include avatar(100px);
}

.template-avatar-circle-sm {
  @include avatar(30px);
}

.avatar-circle-lg {
  @include avatar(250px);
}

#react-doc-viewer #header-bar {
  display: none !important;
}

.doc-viewer-header {
  justify-content: space-between;
  background: white;
  padding: 9px 16px;
  border-radius: 35px;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.f-18 {
  font-size: 18px;
}

.tooltip-style {
  width: auto;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
}
